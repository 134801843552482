.App {
  text-align: center;
  background-color:rgba(38, 45, 57, 0.52);;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  margin-right: 10px;
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #212529;
  transform: translate(0, -5%);
}

p.loading-text {
  size: 16px;
  color: #ffffff;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 42px;
  left: 50%;
  transform: translate(-50%, 0);
  color: #adb5bd;
  font-size: calc(10px + 1vmin);
}

p.brand-text {

}